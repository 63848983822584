import { useQuery } from "@tanstack/react-query";
import useAuth from "./useAuth";
import useAxiosBase from "./useAxiosBase";

const useEstate = () => {
  const { authUser } = useAuth();
  const { getRequest } = useAxiosBase();
  const organizationId = authUser?.user?.organizationId;

  return useQuery({
    queryKey: ["devEstates", organizationId],
    queryFn: () =>
      getRequest({
        url: `/developer/estate/get-all?organizationId=${organizationId}`,
      }).then((res) => res.data?.value?.value?.data),
    retry: 2,
    staleTime: 5000,
  });
};

export default useEstate;
